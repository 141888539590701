import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="footer-rights">
                            © {new Date().getFullYear()} TVVote.ru ltd. All rights reserved.
                </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;