import React from "react"
import Layout from "../components/layout/layout"

const TermsAndConditionsPage = () => (
    <Layout>
    <div className="massive-wrapper">
        <div className="container">
            <div className="content-wrapper">
                <h1>TERMS AND CONDITIONS OF USE</h1>
                <p class="c11 c10"><span class="c2"></span></p>
                <p class="c11"><span class="c5">Last updated: 13.07.2020 </span></p>
                <p class="c11 c10"><span class="c2"></span></p>
                <h2>I. INTRODUCTION</h2>
                <p class="c26"><span class="c5">Welcome to the mobile application TvVote (hereinafter referred to as &ldquo;The Application&rdquo; or &ldquo;Application&rdquo;) which is operated by &bdquo;TVFON&rdquo; EOOD and may be accessed worldwide.</span></p>
                <p class="c26"><span class="c5">By using this Application you warrant that you are at least 14 years of age and agree to be bound by these Terms and Conditions of use. Please read these Terms and Conditions carefully before using the Application and if you have any questions, please contact us at: </span><span class="c5"><a class="c29" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span class="c5">.</span></p>
                <p class="c26"><span class="c12 c3 c19">If you do not agree to any of the conditions contained in these Terms and Conditions, you should not use the Application.</span><span>&nbsp;</span><span class="c12 c3 c19">These Terms and Conditions are available on the Application itself, which allows their storage and reproduction.</span></p>
                <p class="c0 c10"><span class="c12 c3 c9 c20"></span></p>
                <h2>II. PURPOSE AND SCOPE OF THE TERMS AND CONDITIONS</h2>
                <p class="c26">1.1 These Terms and Conditions have been drafted and intend to regulate the relations between &bdquo;TVFON&rdquo; EOOD, a company, registered at the Commercial Register at the Registry Agency with UIC:<span class="c5 c8">&nbsp;</span><span class="c5 c9">205561797</span><span class="c5">, having its seat and registered address at: Republic of Bulgaria, Sofia city, postal code 1164, 26 &ldquo;</span><span class="c5 c21">James Bourchier</span><span class="c5">&rdquo; Boulevard, floor 1, apartment 2, e-mail address: </span><span class="c5"><a class="c29" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span class="c5">&nbsp;and mobile Application TvVote, hereinafter referred to as &quot;Provider&quot;, and the users of the Application, hereinafter referred to as &quot;Users&quot;, in connection with download, installation and use of the Application.</span><span>&nbsp;</span><span class="c5">The other document that govern the relationship between the Provider and the Users in connection with the use of the Application is the Privacy Policy.</span></p>
                <p class="c26"><span class="c3">1.2 </span><span class="c5">These Terms and Conditions will come into force as soon as the User uses the Application for the first time. The Terms and Conditions shall be applied each time the User uses the Application and will have effect from stopping of the use of the Application. The User undertakes to use the Application legally, in accordance with the conditions set forth in these Terms and Conditions.</span></p> 
                <h2>III. DEFINITIONS</h2>
                <p class="c26"><span class="c3 c12">2.1 For the purposes of these Terms and Conditions:</span></p>
                <p class="c0"><span class="c5">a/ </span><span class="c3">Provider</span><span class="c5">&nbsp;is &bdquo;TVFON&rdquo; EOOD</span></p>
                <p class="c0"><span class="c5">b/ </span><span class="c3">User/s</span><span class="c5">&nbsp;is/are:</span></p>
                <p class="c0"><span class="c5">- users of the Application TvVote.</span></p>
                <p class="c0"><span class="c5">d/ </span><span class="c3">Application</span><span class="c5">&nbsp;is mobile Application TvVote.</span></p>
                <p class="c0">2.2 These Terms and Conditions provide information about:</p>
                <p class="c14 c10"><span class="c2"></span></p>
                <p class="c14"><span class="c2">- Identification of the Provider;</span></p>
                <p class="c14"><span class="c2">- Subject matter of the Terms and Conditions;</span></p>
                <p class="c14"><span class="c2">- Characteristics of the Application;</span></p>
                <p class="c14"><span class="c2">- Registration and installation</span></p>
                <p class="c14"><span class="c2">- Pricing and payment terms;</span></p>
                <p class="c14"><span class="c2">-</span><span class="c1">&nbsp;</span><span class="c2">Right of withdrawal;</span></p>
                <p class="c14"><span class="c2">- Force majeure;</span></p>
                <p class="c14"><span class="c2">- Rights, obligations and liability of Users of the Application;</span></p>
                <p class="c14"><span class="c2">- Rights and obligations of the Provider;</span></p>
                <p class="c14"><span class="c2">- Changes to the Application and pricing;</span></p>
                <p class="c14"><span class="c2">- Personal data protection;</span></p>
                <p class="c14"><span class="c2">- Exemption from liability;</span></p>
                <p class="c14"><span class="c2">- Intellectual property rights. Limitations of use;</span></p>
                <p class="c14"><span class="c2">- Transfer of rights prohibited;</span></p>
                <p class="c14"><span class="c2">- Breach and termination;</span></p>
                <p class="c14"><span class="c2">- Final provisions.</span></p>
                <p class="c14 c10"><span class="c2"></span></p>
                <h2>IV. IDENTIFICATION OF THE PROVIDER</h2>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c5">1. </span><span class="c12 c3">Name of the Provider:</span><span class="c3">&nbsp;</span><span class="c5">&bdquo;TVFON&rdquo; EOOD</span></p>
                <p class="c0"><span class="c5">2. </span><span class="c12 c3">Seat and registered address:</span><span class="c3">&nbsp; </span><span class="c5">Republic of Bulgaria, Sofia city, postal code 1164, 26 &ldquo;</span><span class="c5 c21">James Bourchier</span><span class="c5">&rdquo; Boulevard, floor 1, apartment 2</span></p>
                <p class="c0"><span class="c5">3. </span><span class="c12 c3">Contacts: </span><span class="c5">&nbsp;e-mail address: &nbsp;</span><span class="c5"><a class="c29" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span></p>
                <p class="c0"><span class="c5">4. </span><span class="c12 c3">Data for entry in the commercial register and any other public register:</span></p>
                <p class="c0"><span class="c5">- &bdquo;TVFON&rdquo; EOOD is a company, registered in the Commercial Register at the Registry Agency with UIC: </span><span class="c5 c9">205561797</span><span class="c5">.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <h2>V. INFORMATION ABOUT SUPERVISORY AUTHOROTIES</h2>
                <p class="c0"><span class="c12 c3">1. Commission for Personal Data Protection</span></p>
                <p class="c0 c10 c27"><span class="c2"></span></p>
                <p class="c0 c27"><span class="c2">Address: Republic of Bulgaria, Sofia 1592, 2 Prof. Tsvetan Lazarov Blvd.</span></p>
                <p class="c0 c27"><span class="c5">Telephone:</span><span class="c3">&nbsp;</span><span class="c5">02/91-53-519</span></p>
                <p class="c0 c27"><span class="c5">Fax:&nbsp;02/91-53-525</span></p>
                <p class="c0 c27"><span class="c5">&#1045;-mail:</span><span class="c3">&nbsp;</span><span class="c5">kzld@government.bg, kzld@cpdp.bg</span></p>
                <p class="c0"><span class="c5">Web-site:&nbsp;</span><span class="c12 c5"><a class="c29" href="https://www.google.com/url?q=http://www.cpdp.bg/&amp;sa=D&amp;ust=1594730212494000&amp;usg=AOvVaw3V1AlrOUgM3CFRphqm9xMV">www.cpdp.bg</a></span></p>
                <p class="c0 c10"><span class="c12 c3 c20 c9"></span></p>
                <p class="c0"><span class="c12 c3">2. Commission for Consumer Protection</span></p>
                <p class="c0"><span class="c5">Address: Sofia 1000, 4A &quot;Slaveikov&quot; Square, floor. 3, 4 and 6</span></p>
                <p class="c0"><span class="c5">Telephone: 02/933 0565</span></p>
                <p class="c0"><span class="c5">Fax: 02 / 988 42 18</span></p>
                <p class="c0"><span class="c5">Hot line: 0700 111 22</span></p>
                <p class="c0"><span class="c2">Email: info@kzp.bg</span></p>
                <p class="c0"><span class="c5">Website: www.kzp.bg</span></p>
                <p class="c0 c10"><span class="c6"></span></p>
                <h2>VI. SUBJECT MATTER OF THE TERMS AND CONDITIONS</h2>
                <p class="c0 c10"><span class="c17 c3 c9"></span></p>
                <p class="c0"><span class="c3">3.1 </span><span class="c5">The Provider has created the mobile Application TvVot&#1077; that allows for direct communication between the television broadcast and its viewers in real time without the television changing its program structure.</span></p>
                <p class="c0">3.2<span class="c5">The Provider provides and the Users undertake to use the Application under the conditions set forth in these Terms and Conditions.</span><span class="c24">&nbsp;</span><span class="c5">The Provider grants the User who has agreed with these Terms and Conditions the non-exclusive, non-assignable, non-transferable, revocable license, without the right to sublicense to download, install, update and use the Application on a mobile device as expressly permitted by the terms and conditions below and subject to the limitations set forth herein.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">3.3 </span><span class="c5">In its professional activity, the Provider guarantees to the full extent the rights of the Users provided by law, as an additional corrective of its activity are namely the good faith, as well as the consumer and commercial criteria established as good practices.</span></p>
                <p class="c0 c10"><span class="c3 c9 c17"></span></p>
                <h2>VII. CHARACTERISTICS OF THE APPLICATION</h2>
                <p class="c0 c10"><span class="c12 c3 c20 c9"></span></p>
                <p class="c0"><span class="c17 c3 c9">4.1 </span><span class="c2">The</span><span class="c17 c3 c9">&nbsp;</span><span class="c2">Application allows for direct communication between the television broadcast and its viewers in real time without the television changing its program structure. In this way Television becomes truly interactive by enabling viewers to participate in broadcasts. Television is becoming more spectacularly interesting for the younger generation.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c17 c3 c9">4.2 </span><span class="c2">Two types of Users can use the Application: viewers and authors. The author creates questions and suggestions to the viewers, broadcasts them with specific parameters and receives answers by viewers, which can be processed and analyzed later depending on the tasks. The processed results can be shown as graphic or other elements in the TV picture and be an occasion for comment by the viewers in the show. </span></p>
                <p class="c0 c10"><span class="c17 c5 c8"></span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <h2>VIII. REGISTRATION AND INSTALLATION</h2>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">5.1</span><span class="c5">&nbsp;The Application has been developed for each of the two most popular mobile operating systems - iOS and Android. The Application can be used for User&rsquo;s personal and noncommercial use.</span><span class="c24">&nbsp;</span><span class="c5">The Application is available for download and installation on a mobile device at Google Play Store and Apple Store. The normal use of the Application requires the presence of mobile devices (smartphones, tablets) as well as an Internet connection - mobile or wireless (WiFi). If you do not have the appropriate software, hardware and internet connection, you will not be able to use the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0 c27"><span class="c3">5.2</span><span class="c5">&nbsp;In order to use the Application, the User shall install the Application. Installation of the Application is free of charge. By installing the Application the User accesses all functionalities of the Application. When Users install and use the Application they voluntarily provide personal information such as: telephone and e-mail for Users who are viewers and telephone, e-mail and name of Users who are authors. Users who are viewers can be only physical persons, while Users who are authors can be both legal entities and physical persons. The Provider also collects and processes information about the use of the Application.</span></p>
                <p class="c0 c10"><span class="c17 c5 c8"></span></p>
                <p class="c0"><span class="c3">5.3</span><span class="c5">&nbsp;It is prohibited the same User to register more than one User&rsquo;s account.</span></p>
                <p class="c0">5.4<span class="c5">&nbsp;The User confirms that the information provided during the registration is correct complete and accurate. The Provider is not responsible for any typographical errors or misrepresentations of information or information presented in a misleading manner.</span><span>&nbsp;</span><span class="c5">The User undertakes to update and correct outdated and inaccurate data within 7 days of the change of the data. The User is responsible for all actions that are performed through the registered account. The User undertakes to notify the Provider of suspected or unlawful access.</span></p>
                <p class="c0"><span class="c5"><br /></span><span class="c3">5.5</span><span class="c5">&nbsp;The Provider has the right to reject an application for registration of User&rsquo;s account or refuse access to the Application in the event that the User provides or the Provider has doubts that the User has provided incomplete, incorrect or inaccurate information. The Provider has the right to close/delete registered User&rsquo;s account if, at his discretion or if data is available from competent government authorities, that the unauthorized actions are or have been committed through the registered User&rsquo;s account.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <h2>IX. PRICING AND PAYMENT TERMS.</h2>
                <p class="c0 c10"><span class="c17 c3 c9"></span></p>
                <p class="c0"><span class="c3">6.1</span><span class="c5">&nbsp;The download, installation and use of the Application is free of charge.</span></p>
                <p class="c0 c10"><span class="c6"></span></p>
                <h2>X. RIGHT OF WITHDRAWAL</h2>
                <p class="c0 c10"><span class="c6"></span></p>
                <p class="c0"><span class="c3">7.1</span><span class="c5">&nbsp;The User has a fourteen day right of withdrawal without giving any reasons from the date of conclusion of the contract. If the User wishes to exercise the right of withdrawal, the User shall inform the Provider at </span><span class="c5"><a class="c29" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span class="c5">&nbsp;about his/her decision to terminate the contract. The User also has the right to uninstall the Application at any time.</span></p>
                <p class="c0 c10"><span class="c6"></span></p>
                <h2>XI. FORCE MAJEURE</h2>
                <p class="c4"><span class="c3">8.1</span><span class="c5">&nbsp;The Provider shall not be held liable for total or partial non-performance of the obligations under these Terms and Conditions if it is due to &quot;Force Majeure&quot; (Force Majeure). &quot;Force majeure&quot; means an extraordinary circumstance (event) that arose after the signing of the agreement, could not be foreseen and is not dependent on the will of the parties, such as: fire, industrial accidents, military actions, natural disasters - storms, torrential rains, floods, hailstorms, earthquakes, ice, drought, landslides, etc. natural elements, embargo, government bans, strikes, riots, etc.</span></p>
                <p class="c4"><span class="c3">8.2</span><span class="c5">&nbsp;In the event that the Provider has been unable to fulfill its obligations due to a force majeure, the Provider shall be obliged within 10 days to notify in writing the User of its occurrence, as well as the supposed period of validity and termination of force majeure.</span></p>
                <h2>XII. RIGHTS, OBLIGATIONS AND LIABILITY OF USERS OF THE APPLICATION</h2>
                <p class="c0 c10"><span class="c6"></span></p>
                <h4>Permitted uses:</h4>
                <p class="c0 c10"><span class="c12 c3 c20 c9"></span></p>
                <p class="c0"><span class="c3">9.1</span><span class="c5">&nbsp;The User undertakes to use the Application and its functionality legally and in accordance with these Terms and Conditions of Use.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.2</span><span class="c5">&nbsp;The User has the right to register only one account.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.3</span><span class="c5">&nbsp;The User has the right to delete an account that he/she has registered at any time.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.4 </span><span class="c5">The User undertakes to update the information in the registered account in case of any change that has occurred.</span></p>
                <p class="c4"><span class="c3">9.5</span><span class="c5">&nbsp;The User</span><span class="c3">&nbsp;</span><span class="c5">is allowed to install and use the Application only on one device. The User shall own the device on which the Application will be installed and used.</span></p>
                <p class="c0"><span class="c3">9.6</span><span class="c5">&nbsp;The User has the right at any time, at his own discretion, to terminate the use of the Application by terminating his registration. From the moment of termination of the registration the contract between the parties is considered to be automatically terminated, and the Provider suspends the access of the respective User to his profile and has the right to suspend the access to and delete from the servers under his control all located by the respective User content. By uninstalling and/or deleting the Application from the respective mobile device, the contract between the parties is not terminated. The User saves his/her profile and has the right to use the Application from another mobile device.</span></p>
                <h4>Restrictions on use:</h4>
                <p class="c4"><span class="c3">9.7</span><span class="c5">&nbsp;The User is not allowed to publish content that infringes the Intellectual Property Rights of the Provider or third parties.</span></p>
                <p class="c0"><span class="c3">9.8</span><span class="c5">&nbsp;The User is not allowed to publish content in any form (image, text, etc.) that is vulgar, threatening, abusive, obscene or violates the duty of good faith.</span></p>
                <p class="c0 c10"><span class="c17 c3 c9"></span></p>
                <p class="c0"><span class="c3">9.9</span><span class="c5">&nbsp;The User is not allowed to modify, copy, duplicate, create derivative or customized, separate or entire parts of the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.10</span><span class="c5">&nbsp;The User is not allowed to provide to any third party in any manner and in any form, for any commercial or non-commercial purpose, any content, information, know-how or technology that is derived in part or in whole from the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.11</span><span class="c5">&nbsp;The User is not allowed to publish, send or otherwise make computer viruses or the like.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.12</span><span class="c5">&nbsp;The User is not allowed to disable and/or upset the full or partial functionality of the Application as well as the services offered on the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.13</span><span class="c5">&nbsp;The User is not allowed to disrupt the operation of networks or servers that are related to the Services and may not interfere with the provision of services.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.14</span><span class="c5">&nbsp;The User shall not attempt to gain unauthorized access to any part of the Application or to the servers maintained and owned by the Provider.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.15</span><span class="c5">&nbsp;The User undertakes to keep secret of his/her e-mail address and password for accessing of his/her registered account.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.16</span><span class="c5">&nbsp;The User is not allowed to provide his account for use by others. The User is not allowed to use the accounts of other persons.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.17</span><span class="c5">&nbsp;The User shall immediately notify the Provider of any unauthorized use of the password.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.18</span><span class="c5">&nbsp;The User is not entitled to use the Provider&#39;s trademark. The User is responsible for any actions he/she has done in connection with the use of the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">9.19</span><span class="c5">&nbsp;The User is not allowed to send &quot;spam&quot;, &quot;junk mail&quot;, &quot;chain letter&quot; or any unsolicited commercial messages.</span></p>
                <p class="c4"><span class="c3">9.20</span><span class="c5">&nbsp;The User bears the full responsibility for the use of the Application by any third party who has gained access to it. The User shall further bear the full responsibility for any acts and omissions in violation of these Terms and Conditions.</span></p>
                <p class="c4"><span class="c3">9.21</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to lease, rent, sublicense, duplicate, share, lend, sell, give away, distribute, offer, assign or transfer in any way the Application to any third party, in whole or in part, in any form, whether modified or unmodified.</span></p>
                <p class="c4"><span class="c3">9.22</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to alter, modify, convert, translate or in any other way change the Application in any manner.</span></p>
                <p class="c4"><span class="c3">9.23</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to create any adaptation, customization, variant of the Application or other property of the Provider.</span></p>
                <p class="c4"><span class="c3">9.24</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to use the Application in an immoral or defamatory context, or in a way which can be thought illegal, offensive or derogatory.</span></p>
                <p class="c4"><span class="c3">9.25</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to use the Application in a way that violates any third party&#39;s intellectual property right and any applicable law or regulation of any country, state, or other governmental entity.</span></p>
                <p class="c4"><span class="c3">9.26</span><span class="c5">&nbsp;The User shall not and shall ensure that no third party is able to misleadingly represent that the Application was created by the User or a person other than the Provider.</span></p>
                <h4>Rights reserved:</h4>
                <p class="c4"><span class="c3">9.27</span><span class="c5">&nbsp;The Provider is the exclusive proprietor of the Application and the design embodied within the Application and owns all right, title and interest in the Application and the design embodied, together with its organization, structure, code and related files. These Terms and Conditions do not grant the User any right, title or interest in or to the Provider&rsquo;s Properties, except the grant of rights specifically stated in thee Terms and Conditions. Use of the Application in any other manner is strictly forbidden. All rights not granted by this license are expressly reserved.</span></p>
                <h2>XIII. RIGHTS AND OBLIGATIONS OF THE PROVIDER</h2>
                <p class="c4"><span class="c3">10.1</span><span class="c5">&nbsp;The Provider may at any time make changes to the Application at its sole discretion, without obligation to notify.</span></p>
                <p class="c0"><span class="c3">10.2</span><span class="c5">&nbsp;The Provider may at any time update, modify, expand, add or remove services on the Application.</span></p>
                <p class="c0"><span class="c5"><br /></span><span class="c3">10.3</span><span class="c5">&nbsp;The Provider is entitled to temporarily suspend or terminate the provision of a service in case of violation and/or suspicion of breach of these Terms and Conditions of Use or in case of unfair behavior and/or suspicion of unfair behavior of the User.</span></p>
                <p class="c0"><span class="c5"><br /></span><span class="c3">10.4</span><span class="c5">&nbsp;The Provider has the right to refuse the creation of a new account of an User, whose account has been deleted or blocked.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <p class="c0"><span class="c3">10.5</span><span class="c5">&nbsp;The Provider is not obliged to control the manner in which the User uses the Application, and is not responsible for the purposes and activities of the User in connection with the use of the Application.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <h2>XIV. CHANGES TO THE APPLICATION AND PRICING</h2>
                <p class="c4"><span class="c3">11.1</span><span class="c5">&nbsp;The User hereby agrees that:</span></p>
                <ul class="c13 lst-kix_list_1-0 start">
                    <li class="c4 c16"><span class="c2">The Provider shall not be responsible to the User for any modifications and changes to the Application that Provider could make at its discretion;</span></li>
                    <li class="c4 c16"><span class="c2">The Provider shall not be responsible to the User for any decision to discontinue, suspend or terminate the Application or any part(s) of it.</span></li>
                </ul>
                <p class="c4"><span class="c3">11.2</span><span class="c5">&nbsp;The Provider is allowed at its own discretion to:</span></p>
                <ul class="c13 lst-kix_list_1-0">
                    <li class="c4 c16"><span class="c2">Change and modify any features of the Application;</span></li>
                    <li class="c4 c16"><span class="c2">Remove or introduce new Application;</span></li>
                    <li class="c4 c16"><span class="c2">Change the prices for the Application at any time.</span></li>
                </ul>
                <h2>XV. PERSONAL DATA PROTECTION</h2>
                <p class="c0"><span class="c3">12.1</span><span class="c5">&nbsp;The Provider collects and processes personal data of Users by applying all the standards for the protection of personal data under Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC. The Provider respects the privacy of Users and undertakes every effort to protect the personal data of Users against unlawful processing by applying technical and organizational measures, which measures are entirely consistent with state-of-the-art technological developments and provide a level of protection that corresponds to the risks associated with the processing and the nature of the data that should be protected. Detailed information on what personal data the Provider processes, the purposes of the processing of personal data, the period for the storage of personal data, as well as other information in fulfillment of the requirements of Article 13 of Regulation (EC) 2016/679 is available in the Privacy Policy</span><span class="c5 c9">.</span></p>
                <p class="c0 c10"><span class="c6"></span></p>
                <h2>XVI. EXEMPTION FROM LIABILITY</h2>
                <p class="c4"><span class="c6">Disclaimer of Warranties:</span></p>
                <p class="c4"><span class="c3">13.1</span><span class="c5">&nbsp;The Provider warrants and represents that the Application offered by the Provider does not infringe any other intellectual property right. The Provider shall retain all right, title, and interest, including all intellectual property rights, in the Application. The User agrees that the use of the Application is at User&rsquo;s sole risk as to satisfactory quality performance, accuracy and effort. The Provider licenses, and the User accepts the license to use the Application on an &quot;AS IS&quot;, &quot;AS AVAILABLE&quot;, and &quot;WITH ALL FAULTS&quot; basis. The Provider makes no warranties regarding the function or use of the Application, whether express, implied, or statutory, including without limitation, any implied warranties of merchantability or adequacy for any specific purpose. The Application has not been produced in circumstances that could result in environmental damage. The User bears the whole risk for the quality, performance and use of the Application. The Provider does not guarantee that the functions included in the Application will meet User&rsquo;s expectations or that the operation of the Application will be uninterrupted or error free or that the Application is without defects.</span></p>
                <p class="c4"><span class="c6">Disclaimer of Liability:</span></p>
                <p class="c4"><span class="c3">13.2</span><span class="c5">&nbsp;The Provider does not in any way give any guarantee that the Application and its services will be available at any time and from anywhere in the world. The Provider does not guarantee that the Application will remain unchanged and will be maintained indefinitely over time.</span></p>
                <p class="c4"><span class="c3">13.3 </span><span class="c5">The information on the Application could include technical inaccuracies or errors although the Provider uses reasonable efforts to keep all the information on the Application up to date. The Provider takes the necessary steps to ensure that the Users have an uninterrupted access to the Application and also maintains the Application and services with the care of the good merchant for the purpose of providing services to Users with good faith. The Provider has the right to make changes and improvements to the Application at any time without notice. The Provider assumes no liability or responsibility for any damages or losses incurred by the Users as a result of the use of the Application and the information from the Application.</span></p>
                <p class="c4"><span class="c3">13.4</span><span class="c5">&nbsp;The responsibility for the use of the Application is entirely for the User as he/she operates and exploits its capabilities. The Provider assumes no liability or responsibility for any damages and adverse consequences associated with the use of the Application, including in the event of material damage to the relevant technical device of the User.</span></p>
                <p class="c4"><span class="c3">13.5</span><span class="c5">&nbsp;The Provider shall not be held liable for acts performed by Users in violation of these Terms and Conditions of use. The Provider shall not be held liable for damages that are caused by false, misleading, inaccurate information provided by the Users of the Application.</span></p>
                <p class="c4"><span class="c3">13.6</span><span class="c5">&nbsp;The Provider shall not be held liable for any damages resulting from a User&#39;s fault, computer virus, omission, interruption or problem in the system itself, supporting the integrity and structure of the Application.</span></p>
                <p class="c4"><span class="c3">13.7</span><span class="c5">&nbsp;The Provider shall not be held liable to any User whose personal data has been used by another User to use the services provided by the Provider.</span></p>
                <p class="c4"><span class="c3">13.8</span><span class="c5">&nbsp;The Provider shall not be held liable in case the User fails to receive a notification for changes made in these Terms and Conditions in case of accidental event and force majeure, as well as for technical or other reasons.</span></p>
                <p class="c4"><span class="c3 c9">13.9 </span><span class="c5 c9">The Provider shall not be held liable in case the device of the User during the use of the Applications, receives software update/s, which update/s stops or interferes with the use of the &#1040;pplication.</span></p>
                <p class="c4"><span class="c3 c9">13.10 </span><span class="c5 c9">In the event the update of the software of the User&rsquo;s device is terminated, the use of the Application can be stopped or impeded, for which the Provider shall not be held liable.</span><span class="c3 c9">&nbsp; </span></p>
                <p class="c4"><span class="c3">13.11</span><span class="c5">&nbsp;The Provider does not guarantee that the services and content posted on the Application will meet the User&#39;s expectations. In the event of any doubt as to the quality of any service or content posted on the Application, the User should not use the Application. If, in spite of these doubts, the User continues to use the Application, the responsibility, together with all the consequences for that, lies with the User.</span></p>
                <p class="c4"><span class="c3">13.12</span><span class="c5">&nbsp;In no event should Provider be liable to the User or any other party for any incidental, special, consequential or indirect damages, including but not limited to:</span></p>
                <ul class="c13 lst-kix_list_2-0 start">
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from User&rsquo;s inability to use or access the Application;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from any modifications, changes and adjustments which Provider might make to the Application;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from any momentary or constant suspension or cessation of access to the Application from any or all countries;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from any imperfection or defect of the Application;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from any errors or omissions in the Application;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage that may result from Provider&rsquo;s failure to provide correct information to User;</span></li>
                    <li class="c4 c16"><span class="c2">Any loss or damage to any device software and hardware as well as any loss of information;</span></li>
                    <li class="c4 c16"><span class="c2">Any other kind of loss or damage including lost data, lost profits, lost savings, lost business opportunities, loss of goodwill, business interruption, work stoppage, computer failure, malfunction or damage, any claim brought by third party.</span></li>
                </ul>
                <p class="c4"><span class="c3">13.13</span><span class="c5">&nbsp;The User undertakes to notify the Provider in case of any claim or cause of action associated with User&rsquo;s use of the Application.</span></p>
                <h2>XVIII. INTELLECTUAL PROPERTY RIGHTS. LIMITATIONS OF USE</h2>
                <p class="c26"><span class="c3">14.1 </span><span class="c5">The Provider is the exclusive proprietor of the Application and the design embodied within the Application and owns all right, title and interest in and to the Application and the design embodied, together with its organization, structure, code and related files. The Application and design embodied within the Application are protected by copyright, design and other intellectual property laws of the Republic of Bulgaria as well as other international treaties. Any use of the Application as well as its organization, structure, code and related files not expressly permitted by these Terms and Conditions constitutes an infringement on intellectual and industrial property rights. The Application is not sold to the User even after its installation on User&rsquo;s personal device.</span></p>
                <p class="c26"><span class="c3">14.2</span><span class="c5">&nbsp;Use of Provider&#39;s trademark without his consent is a violation of the law. &nbsp;Nothing in these Terms and Conditions shall be construed as &#1072; permission to grant the right to use the posted on the Application trademark. Users are forbidden to make any changes to the trademark of the Provider.</span></p>
                <h2>XIX. TRANSFER OF RIGHTS PROHIBITED</h2>
                <p class="c4"><span class="c3">15.1</span><span class="c5">&nbsp;The User is forbidden to assign in any way the rights and obligations under these Terms and Conditions to any third party without Provider&rsquo;s prior written permission. The User is forbidden to lease, rent, sublicense, lend, give, further distribute or transfer in any way the rights and obligations under these Terms and Conditions to any third party without Provider&rsquo;s prior written permission. The User undertakes not to provide access to his User&rsquo;s account to any third party and to keep the login information to the account in strict confidentiality.</span></p>
                <p class="c4"><span class="c3">15.2</span><span class="c5">&nbsp;Any assignment and transfer in violation of these Terms and Conditions shall be considered invalid, void or unenforceable.</span></p>
                <h2>XX. BREACH AND TERMINATION</h2>
                <p class="c4"><span class="c17 c3 c9">16.1</span><span class="c2">&nbsp;The Provider might terminate User&rsquo;s rights to use the Application and User&rsquo;s account at any time by written notice to the User in the following cases, but not limited to:</span></p>
                <ul class="c13 lst-kix_list_2-0">
                    <li class="c4 c7"><span class="c2">If the User breaches any of the terms and conditions of these Terms and Conditions;</span></li>
                    <li class="c4 c7"><span class="c2">When the User performs actions that violate the reputation, the security and functioning of the Application;</span></li>
                    <li class="c4 c7"><span class="c2">If other person than the User has used the Application using User&rsquo;s access rights;</span></li>
                    <li class="c4 c7"><span class="c2">In case of violation of the Provider&#39;s intellectual property rights;</span></li>
                    <li class="c4 c7"><span class="c2">If the User becomes a competitor of the Provider by developing, licensing or distributing similar Application or services;</span></li>
                    <li class="c4 c7"><span class="c2">On receipt of an order from a competent state authority;</span></li>
                    <li class="c4 c7"><span class="c2">If requested by the User by e-mail (self-initiated account deactivations);</span></li>
                    <li class="c4 c7"><span class="c2">Due to continuous periods if inactivity;</span></li>
                    <li class="c4 c7"><span class="c2">In other reasonable circumstances as determined by the Provider at its discretion.</span></li>
                </ul>
                <p class="c4"><span class="c17 c3 c9">16.2</span><span class="c2">&nbsp;In the event of suspension of User&rsquo;s rights to use the Application the User shall immediately uninstall the Application and deactivate his/her account. The User shall confirm in writing that the foregoing requirement has been completed. The User shall have no further right to use the Application in accordance with these Terms and Conditions. The User agrees that the Provider will not be liable for any termination of User&rsquo;s account or access to the Application.</span></p>
                <p class="c0"><span class="c3">16.3</span><span class="c5">&nbsp;In the event of suspension of User&rsquo;s rights to use the Application the Provider shall not be held liable for any damages suffered and lost profits by the User or third parties, which occurred as a result of the User&#39;s suspension of the User&#39;s profile, termination of his registration as well as following the provision of information or execution of orders to the competent state authorities.</span></p>
                <p class="c0 c10"><span class="c2"></span></p>
                <h2>XXI. FINAL PROVISIONS</h2>
                <h4>Governing Law and Jurisdiction:</h4>
                <p class="c4"><span class="c3">17.1</span><span class="c5">&nbsp;The whole Terms and Conditions are subject to the laws of the Republic of Bulgaria. All issues that are not explicitly regulated in these Terms and Conditions shall be governed by the provisions of the current Bulgarian legislation. Any disputes between the Provider and the User arising out of these Terms and Conditions shall be settled amicably by the parties. If the dispute cannot be resolved amicably, it shall be referred for settlement to the competent court.</span></p>
                <h4>Severability:</h4>
                <p class="c4"><span class="c3">17.2</span><span class="c5">&nbsp;Should one or more provisions of these Terms and Conditions be found to be invalid, void or unenforceable, such provision(s) shall be deemed severable from the remainder of these Terms and Conditions and shall not affect the validity and/or enforceability of the remaining provisions of these Terms and Conditions, which will remain in full force and effect.</span></p>
                <h4>Relationship of the Parties:</h4>
                <p class="c4"><span class="c3">17.3</span><span class="c5">&nbsp;Neither party is, nor shall either party represent itself as, an agent, employee, representative or partner of the other party. Neither party shall have any right, power, or authority to enter into any agreement for or on behalf of the other party, to incur any obligation or liability or otherwise bind the other party. These Terms and Conditions are not intended to create an agency, partnership, association or joint venture between the parties, or to impose any partnership liability</span><span class="c3">&nbsp;</span><span class="c5">upon either. Additionally, no party has the right to bind the other party, in any manner whatsoever and to any other person, except in accordance with the provisions of these Terms and Conditions.</span></p>
                <h4>Survivability:</h4>
                <p class="c4"><span class="c3">17.4</span><span class="c5">&nbsp;The expiration or termination of these Terms and Conditions will not destroy or diminish the binding force and effect of any of the provisions of these Terms and Conditions that expressly, or by reasonable implication, come into or continue in effect on or after such expiration or termination. &nbsp;</span></p>
                <h4>Indemnification:</h4>
                <p class="c4"><span class="c3">17.5</span><span class="c5">&nbsp;User shall defend, indemnify and hold harmless Provider, including its branches, successors, subsidiaries, as well as their directors, employees, shareholders and agents against any and all losses, damages, claims, expenses and costs of any kind arising from any use of the Application other than the uses expressly permitted by these Terms and Conditions. If litigation is initiated and the competent court determines that the User has breached these Terms and Conditions, such party shall be liable and obliged to pay to the Provider the legal fees incurred by the Provider in connection with the litigation, including any appeals therefrom. &nbsp;</span></p>
                <h4>Notice:</h4>
                <p class="c22"><span class="c3">17.6</span><span class="c5">&nbsp;All communications and notifications between the Provider and the User will be deemed to be valid if they are sent in writing.</span></p>
                <h4>Entire Agreement:</h4>
                <p class="c22"><span class="c3">17.7</span><span class="c5">&nbsp;These Terms and Conditions constitute the entire agreement between the parties with respect to the use of the Application and supersedes all prior or contemporaneous oral or written agreements concerning the same. No amendment or modification to these Terms and Conditions will be valid unless in writing and signed by both parties.</span></p>
                <h4>Disclosures:</h4>
                <p class="c4"><span class="c3">17.8</span><span class="c5">&nbsp;The Application are provided by &bdquo;TVFON&rdquo; EOOD, a company registered under the laws of Republic of Bulgaria and having its seat and registered address at: Republic of Bulgaria, Sofia city, postal code 1164, 26 &ldquo;</span><span class="c5 c21">James Bourchier</span><span class="c5">&rdquo; Boulevard, floor 1, apartment 2. You may contact us by sending written correspondence to the above cited address or by emailing us at: &nbsp;</span><span class="c12 c38"><a class="c29" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span class="c5">.</span></p>
                <h4>Updates and upgrades: &nbsp;</h4>
                <p class="c23"><span class="c3">17.9 </span><span class="c5">&#1058;he Provider has the right to update these Terms and Conditions of Use at any time in the future. When this happens, the Provider will notify the Users 30 days before the changes become effective and the User will have the opportunity to accept or refuse to consent to the changes made. It is therefore advisable to carefully read such notice to make sure that you are familiar wi</span><a id="id.gjdgxs"></a><span class="c5">th any changes of the Terms and Conditions. If the User refuses to give his/her consent, the contract will be considered terminated from the moment of this will. Changes to the Terms and Conditions will be made available to Users through the publication on the Application. In case of further disagreement on the part of a User, the latter will have the opportunity to express this disagreement by sending a message to the Provider that he/she does not accept the changes, which will lead to automatic termination of the contract between the User and the Provider of the services provided through the Application, whereby the Provider has the right to immediately suspend the access of the respective User to his profile, to suspend his registration and to delete the User&rsquo;s account.</span></p>
                <p class="c4 c10"><span class="c17 c5 c8"></span></p>
                <div>
                    <p class="c10 c30"><span class="c1"></span></p>
                    <p class="c15"><span class="c35 c17">28 HRISTO BOTEV BOULEVARD, FLOOR 6, OFFICE 10, 1000 SOFIA, REPUBLIC OF BULGARIA</span></p>
                    <p class="c37"><span class="c17 c35">+359&nbsp;882&nbsp;308&nbsp;670 | +359 2&nbsp;851 0324 | K.KADIEVA@KADIEVAIP.COM | WWW.KADIEVAIP.COM</span></p>
                </div>
            </div>
        </div>
    </div>
        </Layout>

)
export default TermsAndConditionsPage