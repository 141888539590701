import React from "react"
import PropTypes from "prop-types"
import Logo from "./../../images/tv-vote-logo.png"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="nav-bar">
        <img src={Logo} alt="logo" className="logo-site"/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
